import React from 'react';
import PropTypes from "prop-types";

const SocialMediaLinksList = (props) => {
    const { socialMediaLinks } = props;
    return (
        <ul className="social">
            { socialMediaLinks.map(socialMediaLink => {
                    const { icon, url, network} = socialMediaLink;
                    return (
                        <li key={network}>
                            <a href={`${url}`}>
                                <i className={`fa ${icon}`}/>
                            </a>
                        </li>)
                }
            )}
        </ul>)
};

SocialMediaLinksList.propTypes = {
    socialMediaLinks: PropTypes.arrayOf(PropTypes.shape({
        network: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
    }))
};


export default SocialMediaLinksList;
