import React, {Fragment} from 'react';
import ImageBars from "../ImageBars/ImageBars";

const Skills = (props) => {
    const { skills } = props;
    const currentSkills = { hard: [], soft: [] };
    skills.forEach((skill) => {
        const {name, icon, level, keywords, cat} = skill;
        currentSkills[cat.toLowerCase()].push({
            key: name, value: level, icon, keywords
        });
    });

    return (
        <Fragment>
            <section id="skills">
                <br/>
                <br/>
                <br/>
                <div className="row">
                    <h2>Skills</h2>
                    <div>
                    <blockquote>
                        The purpose of education is to make good human beings with skill and expertise...
                        Enlightened human beings can be created by teachers.
                        <p> A. P. J. Abdul Kalam </p>
                    </blockquote>
                    </div>
                    <div className="column six">
                        <ImageBars title={'Technology based Skills.'} style={{width: '100%', keyWidth: '150px'}} bars={currentSkills.hard}/>
                    </div>
                    <div className="column six">
                        <ImageBars title={'Soft Skill set.'} style={{width: '100%', keyWidth: '150px'}} bars={currentSkills.soft}/>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </section>
        </Fragment>)
};

Skills.propTypes = {

};


export default Skills;
