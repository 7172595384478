import React, {Fragment} from 'react';
import Icon from '@mdi/react';
import * as Icons from '@mdi/js'
import ReactCountryFlag from 'react-country-flag';
import {getLanguageMapping, getSkillLevels} from './Mappings';
import './ImageBars.scss';

const ImageBars = ({ isLanguage = false, bars = [], title, style = { paddingTop: '3px', width: '175px', keyWidth: '80px', titleColor: '#FFF' }}) => {
    const sort = (a,b) => {
        const valueA = isLanguage ? getLanguageMapping(a.value) : getSkillLevels(a.value);
        const valueB = isLanguage ? getLanguageMapping(b.value) : getSkillLevels(b.value);
        return (valueA > valueB) ? -1 : ((valueB > valueA) ? 1 : 0)
    };

    return (
        <Fragment>
            <h2 style={{color: style.titleColor}}>{title}</h2>
            <table>
                <tbody>
                {bars.sort(sort).map(bar => <tr key={bar.key}>
                        <td className={'icon'} style={{width: '20px', height: '20px', verticalAlign: 'middle'}}>
                            {
                                bar.icon && (isLanguage ? <ReactCountryFlag code={bar.icon} svg/> :
                                    <Icon path={Icons[bar.icon]} size={'20px'}
                                          style={{fill: '#FFF', width: '20px', height: '20px'}} color={'#FFF'}/>)
                            }
                        </td>
                        <td style={{width: style.width, paddingTop: style.paddingTop}}>
                            <div
                                key={`${bar.key}_${bar.icon}`}
                                className="imageBar"
                                style={{height: '20px', lineHeight: '20px'}}
                            >
                                <div
                                    className="imageBar-title"
                                    style={{width: style.keyWidth, color: '#f5f5f5', background: '#1a531e'}}
                                >
                                    <span>{bar.key}</span>
                                </div>
                                <div
                                    className="imageBar-bar"
                                    style={{
                                        left: style.keyWidth,
                                        background: 'rgba(0,175,14,0.63)',
                                        width: `calc((100% - ${style.keyWidth}) * (${isLanguage ? getLanguageMapping(bar.value) : getSkillLevels(bar.value)} * 0.01))`,
                                        transition: 'width 3000ms ease-in-out'
                                    }}
                                />
                                <div className="imageBar-percent" style={{color: '#000000'}}>{bar.value}</div>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </Fragment>
    );
};

export default ImageBars;
