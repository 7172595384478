import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import { Link } from "react-scroll"
import SocialMediaLinksList from "../SocialMediaLinks/SocialMediaLinksList";

class Header extends Component {
    constructor(props) {
        super(props);

        this.header = React.createRef();
        this.nav = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    /**
     * Handle the scroll of the header elements by fading them in and out based on the scroll location of the page
     */
    handleScroll = () => {
        const height = this.header.current.scrollHeight;
        const lastScrollY = window.scrollY;
        if ( (lastScrollY > height*0.1) && (lastScrollY < height*0.9) && (window.outerWidth > 768 ) ) {
            this.nav.current.className = 'fadeOut';
        } else {
            if (lastScrollY < height*0.1) {
                this.nav.current.className = 'fadeIn';
            } else if (lastScrollY < height*0.9) {
                this.nav.current.className = 'fadeOut';
            } else {
                this.nav.current.className = 'opaque fadeIn';
            }
        }
    };

    render() {
        const { headline, introductionText, singlePageSectionLinks } = this.props.headerData;
        const { profiles, image, name } = this.props;

        return (
            <Fragment>
                <header id="home" ref={this.header}>
                    <nav id="nav-wrap" className="fadeIn" ref={this.nav}>
                        {/*TODO: mobile responsive navigation*/}
                        {/*<a className="mobile-btn" title="Show navigation">Show navigation</a>*/}
                        {/*<a className="mobile-btn" title="Hide navigation">Hide navigation</a>*/}
                        <ul id="nav" className="nav">
                            { singlePageSectionLinks.map(({idTo, text}) =>
                                <li key={idTo}>
                                    <Link className="top-link"
                                          activeClass="active"
                                          to={idTo}
                                          spy={true}
                                          smooth={true} duration= {500}>{text}
                                    </Link>
                                </li> )}
                        </ul>
                    </nav>
                    <div className="row banner">
                        <div className="banner-text">
                            <div className="columns">
                                <img className="profile-pic"
                                     src={require(`./../../images/profilePictures/${image}`)}
                                     alt=""
                                />
                            </div>
                            <h2 className="responsive-headline">
                                <span>{headline}</span>
                                <p>I'm {name}</p>
                            </h2>
                            <h3>
                                {introductionText}
                            </h3>
                            <hr/>
                            <SocialMediaLinksList socialMediaLinks={profiles}/>
                        </div>
                    </div>

                    <p className="scrolldown">
                        <Link className="top-link"
                              activeClass="active"
                              to="about"
                              spy={true}
                              smooth={true}
                              duration= {500}>
                            <i className="icon-down-circle"/>
                        </Link>
                    </p>
                </header>
            </Fragment>
        );
    }
}

Header.propTypes = {
    profiles: PropTypes.arrayOf(PropTypes.shape({
        network: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
    })),
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    headerData: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        introductionText: PropTypes.element.isRequired,
        singlePageSectionLinks: PropTypes.arrayOf(PropTypes.shape({
            idTo: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
    })
};

export default Header;
