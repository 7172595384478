import React from 'react';
import PropTypes from "prop-types";
// import { fileDownload } from 'js-file-download';
import LanguageBars from "./LanguageBars";
import ContactDetails from "./ConactDetails";

const About = ({email, summary, image, name, address, phone, languages, privacyView = false, interests}) =>
    (<section id="about">
        <div className="row">
            <div className="columns">
                <img style={{left: '30px', width: '140px', height: '199px' }}
                     className="profile-pic"
                     src={require(`./../../images/profilePictures/${image}`)}
                     alt={''}
                />
                <ContactDetails
                    title={'Contact Details'}
                    email={email}
                    name={name}
                    address={address}
                    privacyView={privacyView}
                    phone={phone}
                />
                <LanguageBars title={'Languages'} languages={languages}/><br />
                {interests && (
                    <div>
                        <h2>Hobbies</h2>
                        <ul>
                            {interests.map(interest => <li> - {interest.name}</li>)}
                        </ul>
                    </div>)
                }
                <p>
                    {/*<a className="button" style={{ background: "#525252" }}>*/}
                    {/*    <i className="fa fa-download"/> Download CV*/}
                    {/*</a>*/}
                </p>
            </div>

            <div className="nine columns main-col">
                <h2>About Me</h2>
                <div style={{ textAlign: 'justify' }}>
                    { summary.split('\n').map((item, i) => <p key={i}>{item}</p> )}
                </div>
            </div>
        </div>
    </section>);


About.propTypes = {
    email: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.arrayOf(PropTypes.string.isRequired),
    languages: PropTypes.arrayOf(PropTypes.shape({
        language: PropTypes.string.isRequired,
        fluency: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired
    })),
    address: PropTypes.shape({
        address: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
    }),
    privacyView: PropTypes.bool,
};

export default About;
