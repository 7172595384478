import React, {Component, Fragment} from 'react';
import './css/default.css';
import './css/fonts.css';
import './css/layout.css';
import './css/magnific-popup.css';
import './css/media-queries.css';
import Header from "./components/Header/Header";
import About from "./components/About/About";
import { Helmet } from "react-helmet";

// Import the configuration of the application
import values from './config/config';
import Footer from "./components/Footer/Footer";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import Skills from "./components/Skills/Skills";

// Get the json data for the CV/Resume
const resume = JSON.parse(JSON.stringify(require('./resumeData/tomaszdacewicz.json')));

class App extends Component {
    render() {
        const { header, about } = values;
        const { privacyView } = about;
        const { basics, languages, skills, interests } = resume;
        const { name, profiles, picture, summary, location, phone, email } = basics;

        return (
            <Fragment>
                <Helmet>
                    <title>{name} - Portfolio</title>
                    <meta name="author" content={name} />
                    <meta name="description" content={`Resume CV ${name}`} />
                    <meta name="keywords" content={`react,cv,${name}`} />
                </Helmet>
                <Header headerData={header} profiles={profiles} image={picture} name={name}/>
                <About
                    summary={summary}
                    languages={languages}
                    email={email}
                    name={name}
                    image={picture}
                    privacyView={privacyView}
                    address={location}
                    phone={phone}
                    interests={interests}
                />
                <Skills
                    skills={skills}
                />
                {/*<Resume summary={summary}*/}
                {/*        languages={languages}*/}
                {/*        email={email}*/}
                {/*        name={name}*/}
                {/*        image={picture}*/}
                {/*        privacyView={privacyView}*/}
                {/*        address={location}*/}
                {/*        phone={phone}*/}
                {/*/>*/}
                <Contact
                    email={email}
                    name={name}
                    privacyView={privacyView}
                    address={location}
                    phone={phone}
                />
                <Footer profiles={profiles} name={name}/>
            </Fragment>
        );
    }
}

export default App;
