/**
* Get language mapping of the level of the langauge based on the levels on the following web page:
    * https://www.cactuslanguage.com/language-level-tests/
    * @param {string} level in text format as coming from the JSON file
* @return {number}
 **/
export const getLanguageMapping = level => {
    switch(level) {
        case 'Beginner':
            return 14.29;
        case 'Elementary':
            return 28.58;
        case 'Intermediate':
            return 42.87;
        case 'Upper Intermediate':
            return 57.16;
        case 'Advanced':
            return 71.45;
        case 'Proficient':
            return 85.74;
        case 'Native':
            return 100;
        default:
            return 0;
    }
};

/**
 * Get the skill based on the level that is passed through.
 * @param level
 * @return {number}
 */
export const getSkillLevels = level => {
    switch(level) {
        case 'Beginner':
            return 14.29;
        case 'Elementary':
            return 28.58;
        case 'Intermediate':
            return 42.87;
        case 'Upper Intermediate':
            return 57.16;
        case 'Advanced':
            return 71.45;
        case 'Knowledgeable':
            return 85.74;
        case 'God Like':
            return 100;
        default:
            return 0;
    }
};
