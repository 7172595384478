import React from 'react';
import SocialMediaLinksList from "../SocialMediaLinks/SocialMediaLinksList";
import {Link} from "react-scroll/modules";

const Footer = (props) => {
    const { profiles, name } = props;
    return (<footer>

    <div className="row">

        <div className="twelve columns">
           <SocialMediaLinksList socialMediaLinks={profiles}/>
            <ul className="copyright">
                <li>&copy; Copyright 2019 dacewicz.io</li>
                <li>{`Built by ${name} using `}
                    <a title="React" href="https://reactjs.org/"> React</a> hosted on
                    <a title="AWS" href="https://aws.amazon.com/"> AWS</a>
                </li>
                <li>Design by <a title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>
            </ul>
        </div>
        <div id="go-top">
            <Link className="top-link"
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration= {500}>
                <i className="icon-up-open"/>
            </Link>
        </div>
    </div>
    </footer>)
};

export default Footer;
