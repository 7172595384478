import React, {Fragment} from 'react';
import PropTypes from "prop-types";

const ContactDetails = ({title, name, address, email, phone, privacyView}) => (
        <Fragment>
            <h2>{title}</h2>
            <p className="address">
                <span>{name}</span><br />
                <span>{!privacyView && <span>{address.address}<br /></span>}
                    {`${address.city}, ${address.region} ${address.postalCode} ${address.countryCode}`}
                    <br />
                        </span>
                <span>{!privacyView && phone.map(num => <span key={num}>{num}<br /></span> )}</span>
                <span><a href={`mailto:${email}?Subject=Request%20From%20site`}>{email}</a></span>
            </p>
        </Fragment>
    )
;

ContactDetails.propTypes = {
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.arrayOf(PropTypes.string.isRequired),
    address: PropTypes.shape({
        address: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
    }),
    privacyView: PropTypes.bool.isRequired
};

export default ContactDetails;
