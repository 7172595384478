import React from "react";

const values = {
    appSettings: {
        scrollDuration: 500,
    },
    header: {
        headline: "Hello world.",
        introductionText:
            (<div>I'm a Berlin, based <span>Technical Team Lead</span>, <span>Full Stack Developer</span>, <span>Trainer </span>
                in <span>Web Technologies</span> with my focus mainly on <span>Frontend Technologies</span>.
                Working as a consultant for major companies using Lean Startup methodologies to develop,
                plan and execute projects from large to small.
                <p/>
                <p> Welcome to my world. Let's begin by scrolling down to find out more about me. </p></div>)
        ,
        singlePageSectionLinks:
            [
                {
                    idTo: 'home',
                    text: 'Home'
                },
                {
                    idTo: 'about',
                    text: 'About'
                },
                {
                    idTo: 'skills',
                    text: 'Skills'
                },
                // {
                //     idTo: 'resume',
                //     text: 'Resume'
                // },
                // {
                //     idTo: 'work',
                //     text: 'Work'
                // },
                // {
                //     idTo: 'additionalWork',
                //     text: 'Additional Work'
                // },
                {
                    idTo: 'contact',
                    text: 'Contact'
                }
            ]
    },
    about: {
        privacyView: true,
    },
    resumeFile: 'resumeData/tomaszDacewicz.json'
};

export default values;
