import React from 'react';
import PropTypes from "prop-types";
import ImageBars from "../ImageBars/ImageBars";

const LanguageBars = ({title, languages}) => <ImageBars title={title} bars={languages.map(language => ({
    key: language.language,
    value: language.fluency,
    icon: language.code
}))} isLanguage={true} />;

LanguageBars.propTypes = {
    title: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.shape({
        language: PropTypes.string.isRequired,
        fluency: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired
    }))
};

export default LanguageBars;
