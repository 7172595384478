import React, {Fragment} from "react";
import ReactContactForm from 'react-mail-form';
import ContactDetails from "../About/ConactDetails";
import PropTypes from "prop-types";

const Contact = ({email, name, address, privacyView = false, phone}) => (
        <Fragment>
            <section id="contact">
                <div className="row section-head">

                    <div className="two columns header-col">
                        <h1><span>Get In Touch.</span></h1>
                    </div>

                    <div className="ten columns">
                        <h2 style={{color: 'rgba(255,255,255,0.61)'}}>I would love to hear from you!</h2>
                        <p className="lead">
                            If you have any questions, queries or would just like to get to know me drop me an email, and lets connect.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="eight columns" style={{textAlign: 'center'}}>
                        <ReactContactForm to={email} />
                    </div>
                    <aside className="four columns footer-widgets">
                        <div className="widget widget_contact">
                            <ContactDetails
                                title={'Address'}
                                email={email}
                                name={name}
                                address={address}
                                privacyView={privacyView}
                                phone={phone}
                            />
                        </div>
                    </aside>
                </div>
            </section>
        </Fragment>
    );

ContactDetails.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.arrayOf(PropTypes.string.isRequired),
    address: PropTypes.shape({
        address: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
    }),
    privacyView: PropTypes.bool.isRequired
};

export default Contact;
